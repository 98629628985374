import { TreeemapNode } from "app/components/Charts/treemap/common/node";
import { TreemapTooltip } from "app/components/Charts/treemap/common/tooltip";

export interface TreeemapNodeData {
  ref: string;
  name: string;
  color: string;
  value: number;
  committed: number;
  percentage: number | null;
  orgs?: TreeemapNodeData[];
}

export interface TreemapDataModel {
  name: string;
  color: string;
  children: TreeemapNodeData[];
}

export interface TreemapProps {
  label: string;
  height?: number;
  data: TreemapDataModel;
  showSmTooltip?: boolean;
  selectedVizItemId: string | number | null;
  setSelectedVizItem: React.Dispatch<string | number | null>;
}

export const TreemapVizColors = [
  "#E5F0F0",
  "#DCECEC",
  "#D0E5E5",
  "#C1DDDD",
  "#ACD1D1",
  "#8DB7B5",
];

export const TreemapVizModel = {
  label: "name",
  animate: true,
  value: "value",
  innerPadding: -0.5,
  outerPadding: 1,
  borderWidth: 1,
  borderColor: "black",
  tile: "squarify",
  leavesOnly: true,
  identity: "ref",
  motionDamping: 11,
  orientLabel: false,
  motionStiffness: 90,
  labelTextColor: "#fff",
  tooltip: TreemapTooltip,
  nodeComponent: TreeemapNode,
  colors: (node: any): string => node.color,
  theme: {
    tooltip: {
      container: {
        padding: 15,
        borderRadius: 15,
      },
    },
  },
};

export const TreemapMockData: TreemapDataModel = {
  name: "",
  color: "",
  children: [
    {
      ref: "10",
      name: "Government",
      value: 7235808331.840007,
      committed: 8361950120.1,
      percentage: 86.53254597210481,
      orgs: [
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 7235808331.840007,
          committed: 8361950120.1,
          orgs: [],
          percentage: 86.53254597210481,
          color: "#DCECEC",
        },
        {
          ref: "22000",
          name: "Finnish NGO, Abilis-saatio",
          value: 892922033.13,
          committed: 889400395,
          orgs: [],
          percentage: 100.3959564387196,
          color: "#8DB7B5",
        },
        {
          ref: "42001",
          name: "European Commission (EC)",
          value: 787447333.24,
          committed: 965328502.1,
          orgs: [],
          percentage: 81.57299111410957,
          color: "#8DB7B5",
        },
        {
          ref: "11000",
          name: "Embassy of Finland",
          value: 579009969.73,
          committed: 610949164,
          orgs: [],
          percentage: 94.7722010026353,
          color: "#8DB7B5",
        },
        {
          ref: "42003",
          name: "European Development Fund",
          value: 345983583.51,
          committed: 482791911,
          orgs: [],
          percentage: 71.66308623385366,
          color: "#8DB7B5",
        },
        {
          ref: "44002",
          name: "International Development Association",
          value: 341671076,
          committed: 482057000,
          orgs: [],
          percentage: 70.87773354603294,
          color: "#8DB7B5",
        },
        {
          ref: "41119",
          name: "UN Fund for Population",
          value: 257638767.02,
          committed: 256137880,
          orgs: [],
          percentage: 100.58596839327319,
          color: "#8DB7B5",
        },
        {
          ref: "12000",
          name: "Recipient Government",
          value: 242505629.75,
          committed: 227356437,
          orgs: [],
          percentage: 106.66319060497943,
          color: "#8DB7B5",
        },
        {
          ref: "41122",
          name: "UN Childrens Fund",
          value: 227919438,
          committed: 187574202,
          orgs: [],
          percentage: 121.50894716321383,
          color: "#8DB7B5",
        },
        {
          ref: "50000",
          name: "Other, consultant",
          value: 207593915,
          committed: 51167481,
          orgs: [],
          percentage: 405.7145494420568,
          color: "#8DB7B5",
        },
        {
          ref: "41114",
          name: "United Nations Development Programme (UNDP)",
          value: 197943430,
          committed: 204997639,
          orgs: [],
          percentage: 96.55888280742589,
          color: "#8DB7B5",
        },
        {
          ref: "44000",
          name: "World Bank (WB)",
          value: 197327069,
          committed: 206441305,
          orgs: [],
          percentage: 95.58507150494907,
          color: "#8DB7B5",
        },
        {
          ref: "41121",
          name:
            "United Nations Office of the United Nations High Commissioner for Refugees",
          value: 191453624,
          committed: 186967236,
          orgs: [],
          percentage: 102.39955839107553,
          color: "#8DB7B5",
        },
        {
          ref: "41140",
          name: "World Food Programme (WFP)",
          value: 190610476,
          committed: 204610476,
          orgs: [],
          percentage: 93.15773059440026,
          color: "#8DB7B5",
        },
        {
          ref: "46003",
          name: "African Development Fund (Afr.DF)",
          value: 181938706,
          committed: 258750852,
          orgs: [],
          percentage: 70.31424422130985,
          color: "#8DB7B5",
        },
        {
          ref: "10000",
          name: "Public sector",
          value: 159774262,
          committed: 146087773,
          orgs: [],
          percentage: 109.36867522787139,
          color: "#8DB7B5",
        },
        {
          ref: "52000",
          name: "Consultants",
          value: 146833449.44,
          committed: 204304657,
          orgs: [],
          percentage: 71.86984946701435,
          color: "#8DB7B5",
        },
        {
          ref: "41146",
          name:
            "United Nations Entity for Gender Equality and the Empowerment of Women",
          value: 137970284.96,
          committed: 137370045,
          orgs: [],
          percentage: 100.4369511271544,
          color: "#8DB7B5",
        },
        {
          ref: "44004",
          name: "International Finance Corporation",
          value: 115200000,
          committed: 116140000,
          orgs: [],
          percentage: 99.19063199586707,
          color: "#8DB7B5",
        },
        {
          ref: "47044",
          name: "Global Environment Facility",
          value: 108268304,
          committed: 149891425,
          orgs: [],
          percentage: 72.231152649326,
          color: "#8DB7B5",
        },
        {
          ref: "51000",
          name: "South Centre",
          value: 97098386.49000002,
          committed: 112818385,
          orgs: [],
          percentage: 86.06610216056542,
          color: "#8DB7B5",
        },
        {
          ref: "21000",
          name: "International Peace Institute",
          value: 86489821.03999999,
          committed: 101121480,
          orgs: [],
          percentage: 85.53061232885436,
          color: "#8DB7B5",
        },
        {
          ref: "90000",
          name: "Consultants",
          value: 75323609.39000002,
          committed: 59189552,
          orgs: [],
          percentage: 127.25828604007683,
          color: "#8DB7B5",
        },
        {
          ref: "41108",
          name: "International Fund for Agricultural Development",
          value: 71400000,
          committed: 90620000,
          orgs: [],
          percentage: 78.79055396159788,
          color: "#8DB7B5",
        },
        {
          ref: "41147",
          name: "United Nations Central Emergency Response Fund (CERF)",
          value: 63365570,
          committed: 63365570,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41317",
          name: "Green Climate Fund",
          value: 56850000,
          committed: 180000000,
          orgs: [],
          percentage: 31.583333333333336,
          color: "#8DB7B5",
        },
        {
          ref: "44007",
          name: "IDA - Multilateral Debt Relief Iniative",
          value: 54760059.57,
          committed: 124864986,
          orgs: [],
          percentage: 43.855416417537576,
          color: "#8DB7B5",
        },
        {
          ref: "41130",
          name:
            "United Nations Relief and Works Agency for Palestine Refugees in the Near East",
          value: 50500000,
          committed: 51500000,
          orgs: [],
          percentage: 98.05825242718447,
          color: "#8DB7B5",
        },
        {
          ref: "41135",
          name: "United Nations Volunteers (UNV)",
          value: 49238725.53,
          committed: 68909417,
          orgs: [],
          percentage: 71.45427675001227,
          color: "#8DB7B5",
        },
        {
          ref: "41307",
          name: "World Health Organization (WHO)",
          value: 44855528,
          committed: 47241565,
          orgs: [],
          percentage: 94.94928459715507,
          color: "#8DB7B5",
        },
        {
          ref: "41000",
          name: "United Nations Central Emergency Response Fund (CERF)",
          value: 44312227.54,
          committed: 53805797,
          orgs: [],
          percentage: 82.35586128386872,
          color: "#8DB7B5",
        },
        {
          ref: "23000",
          name: "Twaweza",
          value: 41723811,
          committed: 57584076,
          orgs: [],
          percentage: 72.45720327265475,
          color: "#8DB7B5",
        },
        {
          ref: "41116",
          name: "United Nations Environment Programme (UNEP)",
          value: 40866308,
          committed: 38313666,
          orgs: [],
          percentage: 106.66248434696904,
          color: "#8DB7B5",
        },
        {
          ref: "46004",
          name: "Asian Development Bank",
          value: 40787487.26,
          committed: 35826000,
          orgs: [],
          percentage: 113.84884514040081,
          color: "#8DB7B5",
        },
        {
          ref: "41301",
          name: "Food and Agriculture Organisation (FAO)",
          value: 38217105,
          committed: 41047624,
          orgs: [],
          percentage: 93.10430489228804,
          color: "#8DB7B5",
        },
        {
          ref: "42000",
          name: "Commission of the European Communities",
          value: 33917589,
          committed: 36317710,
          orgs: [],
          percentage: 93.39132065320199,
          color: "#8DB7B5",
        },
        {
          ref: "47131",
          name: "Organization for Security and Co-operation in Europe (OSCE)",
          value: 30905759.580000002,
          committed: 35549029,
          orgs: [],
          percentage: 86.93840717843517,
          color: "#8DB7B5",
        },
        {
          ref: "12001",
          name: "Recipient Government",
          value: 30669846.129999995,
          committed: 14363622,
          orgs: [],
          percentage: 213.52445873331948,
          color: "#8DB7B5",
        },
        {
          ref: "41302",
          name: "International Labour Organisation (ILO)",
          value: 27840445,
          committed: 27074674,
          orgs: [],
          percentage: 102.82836646528044,
          color: "#8DB7B5",
        },
        {
          ref: "13000",
          name: "Third Country Government",
          value: 27337439,
          committed: 17175000,
          orgs: [],
          percentage: 159.16995050946144,
          color: "#8DB7B5",
        },
        {
          ref: "41127",
          name:
            "United Nations Office of Co-ordination of Humanitarian Affairs",
          value: 27000000,
          committed: 28500000,
          orgs: [],
          percentage: 94.73684210526315,
          color: "#8DB7B5",
        },
        {
          ref: "22502",
          name: "Pelastakaa lapset ry",
          value: 25354172,
          committed: 23648933,
          orgs: [],
          percentage: 107.21063821357184,
          color: "#8DB7B5",
        },
        {
          ref: "41304",
          name: "UN Educational Scientific and Cultural Organization",
          value: 24275714,
          committed: 32497237,
          orgs: [],
          percentage: 74.70085533733221,
          color: "#8DB7B5",
        },
        {
          ref: "47066",
          name: "International Organization for Migration (IOM)",
          value: 23659374,
          committed: 30060408,
          orgs: [],
          percentage: 78.70609740227079,
          color: "#8DB7B5",
        },
        {
          ref: "47015",
          name:
            "Consultative Group on International Agricultural Research (CGIAR)",
          value: 22250000,
          committed: 6000000,
          orgs: [],
          percentage: 370.83333333333337,
          color: "#8DB7B5",
        },
        {
          ref: "47129",
          name: "Global Environment Facility",
          value: 22216978,
          committed: 22216978,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41128",
          name: "United Nations Office on Drugs and Crime (UNODC)",
          value: 19988000,
          committed: 21014000,
          orgs: [],
          percentage: 95.11754068716094,
          color: "#8DB7B5",
        },
        {
          ref: "41110",
          name: "Joint United Nations Programme on HIV/AIDS (UNAIDS)",
          value: 19200000,
          committed: 36000000,
          orgs: [],
          percentage: 53.333333333333336,
          color: "#8DB7B5",
        },
        {
          ref: "21016",
          name: "International Committee of the Red Cross (ICRC)",
          value: 19000000,
          committed: 31000000,
          orgs: [],
          percentage: 61.29032258064516,
          color: "#8DB7B5",
        },
        {
          ref: "47000",
          name: "Intergovernmental Authority on Development",
          value: 17818312,
          committed: 13604342,
          orgs: [],
          percentage: 130.9751842463237,
          color: "#8DB7B5",
        },
        {
          ref: "61004",
          name: "FCG International Ltd",
          value: 17749550.54,
          committed: 1806089,
          orgs: [],
          percentage: 982.761676750149,
          color: "#8DB7B5",
        },
        {
          ref: "41134",
          name: "United Nations University (UNU)",
          value: 17322046.849999998,
          committed: 25779359,
          orgs: [],
          percentage: 67.1934738563515,
          color: "#8DB7B5",
        },
        {
          ref: "62000",
          name: "Private sector in recipient country",
          value: 17186135,
          committed: 9108773,
          orgs: [],
          percentage: 188.67672956610073,
          color: "#8DB7B5",
        },
        {
          ref: "61003",
          name: "FCA Investments Ltd",
          value: 16000000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "46005",
          name: "Asian Development Fund (AsDF)",
          value: 15750000,
          committed: 28743403,
          orgs: [],
          percentage: 54.795182045772385,
          color: "#8DB7B5",
        },
        {
          ref: "46012",
          name: "Inter-American Development Bank",
          value: 15314152,
          committed: 15578957,
          orgs: [],
          percentage: 98.30023922654128,
          color: "#8DB7B5",
        },
        {
          ref: "46002",
          name: "African Development Bank (Afr.DB)",
          value: 15186541.04,
          committed: 34042000,
          orgs: [],
          percentage: 44.61118923682509,
          color: "#8DB7B5",
        },
        {
          ref: "44001",
          name: "International Bank for Reconstruction and Development (IBRD)",
          value: 14277371,
          committed: 12400234,
          orgs: [],
          percentage: 115.13791594577974,
          color: "#8DB7B5",
        },
        {
          ref: "46000",
          name: "Nordic Development Fund",
          value: 13180000,
          committed: 85980000,
          orgs: [],
          percentage: 15.329146313096068,
          color: "#8DB7B5",
        },
        {
          ref: "11001",
          name: "Tax Administration",
          value: 13157492.26,
          committed: 89344885,
          orgs: [],
          percentage: 14.726631815576235,
          color: "#8DB7B5",
        },
        {
          ref: "41310",
          name: "United Nations Department of Peacekeeping Operations (UNDPKO)",
          value: 13134008,
          committed: 16570019,
          orgs: [],
          percentage: 79.26368702413679,
          color: "#8DB7B5",
        },
        {
          ref: "61009",
          name: "Ramboll Finland oy",
          value: 13037398.37,
          committed: 4629744,
          orgs: [],
          percentage: 281.60084812464794,
          color: "#8DB7B5",
        },
        {
          ref: "45001",
          name: "WTO - International Trade Centre",
          value: 12670966.01,
          committed: 18285000,
          orgs: [],
          percentage: 69.29705228329232,
          color: "#8DB7B5",
        },
        {
          ref: "41502",
          name: "United Nations Office for Project Services (UNOPS)",
          value: 12400299.06,
          committed: 31419046,
          orgs: [],
          percentage: 39.46745887828676,
          color: "#8DB7B5",
        },
        {
          ref: "41123",
          name: "UN Industrial Development Organization",
          value: 12353837,
          committed: 11853837,
          orgs: [],
          percentage: 104.21804349089665,
          color: "#8DB7B5",
        },
        {
          ref: "21023",
          name: "Intl. Planned Parenthood Federation",
          value: 10600000,
          committed: 23450000,
          orgs: [],
          percentage: 45.20255863539446,
          color: "#8DB7B5",
        },
        {
          ref: "46026",
          name: "Asian Infrastructure Investment Bank (AIIB)",
          value: 10087928,
          committed: 19956428,
          orgs: [],
          percentage: 50.54976772396343,
          color: "#8DB7B5",
        },
        {
          ref: "47061",
          name: "Inter-American Institute for Co-operation on Agriculture",
          value: 9855304,
          committed: 2430000,
          orgs: [],
          percentage: 405.56806584362135,
          color: "#8DB7B5",
        },
        {
          ref: "46015",
          name: "European Bank for Reconstruction and Development",
          value: 9100000,
          committed: 6000000,
          orgs: [],
          percentage: 151.66666666666666,
          color: "#8DB7B5",
        },
        {
          ref: "41141",
          name: "UN Peacebuilding Fund",
          value: 9000000,
          committed: 9000000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41305",
          name: "United Nations (UN)",
          value: 8880375,
          committed: 10511711,
          orgs: [],
          percentage: 84.48077577475256,
          color: "#8DB7B5",
        },
        {
          ref: "47078",
          name: "Montreal Protocol",
          value: 7770671,
          committed: 7770671,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41137",
          name:
            "UN Voluntary Fund for Technical Co-operation in the Field of Human Rights",
          value: 7520000,
          committed: 7520000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "30011",
          name: "International Union for Conservation of Nature",
          value: 7405648,
          committed: 5731803,
          orgs: [],
          percentage: 129.20276569170295,
          color: "#8DB7B5",
        },
        {
          ref: "41126",
          name: "UN Mine Action Service",
          value: 7362366,
          committed: 7250000,
          orgs: [],
          percentage: 101.54987586206896,
          color: "#8DB7B5",
        },
        {
          ref: "41314",
          name: "United Nations Economic Commission for Europe (UNECE)",
          value: 7342783.87,
          committed: 796788,
          orgs: [],
          percentage: 921.547998965848,
          color: "#8DB7B5",
        },
        {
          ref: "47128",
          name: "Nordic Development Fund",
          value: 6942000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "41315",
          name:
            "United Nations International Strategy for Disaster Reduction (ISDR)",
          value: 6800000,
          committed: 8800000,
          orgs: [],
          percentage: 77.27272727272727,
          color: "#8DB7B5",
        },
        {
          ref: "61000",
          name: "Private sector in provider country",
          value: 6766745.379999999,
          committed: 991211,
          orgs: [],
          percentage: 682.674564749584,
          color: "#8DB7B5",
        },
        {
          ref: "41124",
          name: "United Nations Development Fund for Women (UNIFEM)",
          value: 6635000,
          committed: 22700000,
          orgs: [],
          percentage: 29.22907488986784,
          color: "#8DB7B5",
        },
        {
          ref: "41143",
          name: "World Health Organisation (WHO), core voluntary contributions",
          value: 6588383,
          committed: 8487441,
          orgs: [],
          percentage: 77.6250815764139,
          color: "#8DB7B5",
        },
        {
          ref: "47138",
          name: "Council of Europe",
          value: 6312469,
          committed: 7556593,
          orgs: [],
          percentage: 83.53591360550978,
          color: "#8DB7B5",
        },
        {
          ref: "41313",
          name: "United Nations High Commissioner for Human Rights",
          value: 6279675,
          committed: 7828260,
          orgs: [],
          percentage: 80.21801779705835,
          color: "#8DB7B5",
        },
        {
          ref: "47045",
          name: "Global Fund for HIV/AIDS, TB and Malaria (GFATM)",
          value: 6000000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "61002",
          name: "Private export company",
          value: 5700675.21,
          committed: 1533049,
          orgs: [],
          percentage: 371.85212018663464,
          color: "#8DB7B5",
        },
        {
          ref: "47130",
          name: "Global Environment Facility",
          value: 5700000,
          committed: 5700000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "21053",
          name: "IPAS",
          value: 5400000,
          committed: 14700000,
          orgs: [],
          percentage: 36.734693877551024,
          color: "#8DB7B5",
        },
        {
          ref: "41309",
          name: "World Meteorological Organisation (WMO)",
          value: 5058795,
          committed: 5070795,
          orgs: [],
          percentage: 99.76335071719524,
          color: "#8DB7B5",
        },
        {
          ref: "47111",
          name: "Adaptation Fund",
          value: 5000000,
          committed: 5000000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47501",
          name: "Global Partnership for Education",
          value: 4841388,
          committed: 2000000,
          orgs: [],
          percentage: 242.0694,
          color: "#8DB7B5",
        },
        {
          ref: "41112",
          name: "United Nations Conference on Trade and Development (UNCTAD)",
          value: 4582737.52,
          committed: 5108000,
          orgs: [],
          percentage: 89.71686609240408,
          color: "#8DB7B5",
        },
        {
          ref: "21037",
          name: "Women's World Banking",
          value: 4250000,
          committed: 4450000,
          orgs: [],
          percentage: 95.50561797752809,
          color: "#8DB7B5",
        },
        {
          ref: "47005",
          name: "African Union",
          value: 4182297,
          committed: 3082000,
          orgs: [],
          percentage: 135.7007462686567,
          color: "#8DB7B5",
        },
        {
          ref: "32000",
          name: "Multilateral Organisation Performance Assessment Network",
          value: 4136027,
          committed: 5309200,
          orgs: [],
          percentage: 77.90301740375197,
          color: "#8DB7B5",
        },
        {
          ref: "41312",
          name:
            "United Nations Peacebuilding Fund (Window One: Flexible Contributions Only)",
          value: 4000000,
          committed: 4000000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47058",
          name:
            "International Institute for Democracy and Electoral Assistance",
          value: 3940000,
          committed: 4790000,
          orgs: [],
          percentage: 82.25469728601253,
          color: "#8DB7B5",
        },
        {
          ref: "47080",
          name: "Organisation for Economic Co-operation and Development",
          value: 3920000,
          committed: 4130000,
          orgs: [],
          percentage: 94.91525423728814,
          color: "#8DB7B5",
        },
        {
          ref: "21033",
          name: "Transparency International",
          value: 3800000,
          committed: 4600000,
          orgs: [],
          percentage: 82.6086956521739,
          color: "#8DB7B5",
        },
        {
          ref: "30003",
          name: "Global e-Schools and Communities Initiative",
          value: 3687000,
          committed: 3746000,
          orgs: [],
          percentage: 98.42498665242925,
          color: "#8DB7B5",
        },
        {
          ref: "47077",
          name: "Mekong River Commission (MRC)",
          value: 3630000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "43000",
          name: "Kansainvalinen valuuttarahasto",
          value: 3000000,
          committed: 3000000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "44003",
          name: "IDA - HIPC Initiative Trust Fund",
          value: 3000000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "62003",
          name: "PMTC Zambia Ltd",
          value: 2982240.4299999997,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "41120",
          name: "UN Human Settlements Programme",
          value: 2880000,
          committed: 1880000,
          orgs: [],
          percentage: 153.19148936170214,
          color: "#8DB7B5",
        },
        {
          ref: "31004",
          name:
            "Extractive Industries Transparency Initiative International Secretariat",
          value: 2550000,
          committed: 1250000,
          orgs: [],
          percentage: 204,
          color: "#8DB7B5",
        },
        {
          ref: "45000",
          name: "World Trade Organization",
          value: 2549000,
          committed: 2549000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47122",
          name: "The Gavi Alliance",
          value: 2500000,
          committed: 2500000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "21047",
          name: "IFAP/Agricord",
          value: 2500000,
          committed: 3000000,
          orgs: [],
          percentage: 83.33333333333334,
          color: "#8DB7B5",
        },
        {
          ref: "47141",
          name: "African Tax Administration Forum",
          value: 2356690.48,
          committed: 4000000,
          orgs: [],
          percentage: 58.917262,
          color: "#8DB7B5",
        },
        {
          ref: "21042",
          name: "International Peacebuilding Alliance",
          value: 2250000,
          committed: 2250000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41103",
          name: "Economic Commission for Africa",
          value: 1951156,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "41107",
          name:
            "International Atom Energy Agency (Contributions to Technical Cooperation Fund Only) (IAEA-TCF)",
          value: 1947395,
          committed: 2323464,
          orgs: [],
          percentage: 83.814296240441,
          color: "#8DB7B5",
        },
        {
          ref: "21017",
          name:
            "International Centre for Trade and Sustainable Development (ICTSD)",
          value: 1873948.45,
          committed: 1374000,
          orgs: [],
          percentage: 136.3863500727802,
          color: "#8DB7B5",
        },
        {
          ref: "31000",
          name: "Public-Private Partnership",
          value: 1650000,
          committed: 1150000,
          orgs: [],
          percentage: 143.47826086956522,
          color: "#8DB7B5",
        },
        {
          ref: "47089",
          name: "Southern African Development Community (SADC)",
          value: 1534963,
          committed: 1534964,
          orgs: [],
          percentage: 99.99993485189229,
          color: "#8DB7B5",
        },
        {
          ref: "46016",
          name:
            "European Bank for Reconstruction and Development ? technical co-operation and special funds (ODA-eli",
          value: 1500000,
          committed: 1500000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "20000",
          name: "Academy of Finland",
          value: 1497629,
          committed: 6234593,
          orgs: [],
          percentage: 24.02127933611705,
          color: "#8DB7B5",
        },
        {
          ref: "21049",
          name: "European Centre for Development Policy Management",
          value: 1286416,
          committed: 2205000,
          orgs: [],
          percentage: 58.34086167800453,
          color: "#8DB7B5",
        },
        {
          ref: "11003",
          name: "Teollisen yhteistyon rahasto Oy",
          value: 1200618.19,
          committed: 212434728,
          orgs: [],
          percentage: 0.5651703943622627,
          color: "#8DB7B5",
        },
        {
          ref: "63002",
          name: "Particip GmbH",
          value: 1178597.7799999998,
          committed: 2546400,
          orgs: [],
          percentage: 46.28486412189757,
          color: "#8DB7B5",
        },
        {
          ref: "41101",
          name: "Convention to Combat Desertification (UNCCD)",
          value: 1136512,
          committed: 1336512,
          orgs: [],
          percentage: 85.03567495091701,
          color: "#8DB7B5",
        },
        {
          ref: "45002",
          name: "WTO - Advisory Centre on WTO Law",
          value: 1100000,
          committed: 2200000,
          orgs: [],
          percentage: 50,
          color: "#8DB7B5",
        },
        {
          ref: "41148",
          name: "United Nations Department of Political Affairs",
          value: 1057692,
          committed: 1307692,
          orgs: [],
          percentage: 80.88234844290551,
          color: "#8DB7B5",
        },
        {
          ref: "21050",
          name: "Geneva Call",
          value: 1025059,
          committed: 1267234,
          orgs: [],
          percentage: 80.88948055370989,
          color: "#8DB7B5",
        },
        {
          ref: "41129",
          name:
            "United Nations Research Institute for Social Development (UNRISD)",
          value: 1004110,
          committed: 1216389,
          orgs: [],
          percentage: 82.54842817552608,
          color: "#8DB7B5",
        },
        {
          ref: "21057",
          name: "International Centre For Transitional Justice (ICTJ)",
          value: 953503,
          committed: 950000,
          orgs: [],
          percentage: 100.36873684210525,
          color: "#8DB7B5",
        },
        {
          ref: "47123",
          name: "Geneva International Centre for Humanitarian Demining (GICHD)",
          value: 940000,
          committed: 1400000,
          orgs: [],
          percentage: 67.14285714285714,
          color: "#8DB7B5",
        },
        {
          ref: "41125",
          name: "United Nations Institute for Training and Research (UNITAR)",
          value: 810000,
          committed: 790000,
          orgs: [],
          percentage: 102.53164556962024,
          color: "#8DB7B5",
        },
        {
          ref: "41138",
          name: "United Nations Voluntary Fund for Victims of Torture (UNVFTV)",
          value: 760000,
          committed: 760000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41303",
          name: "International Telecommunications Union (ITU)",
          value: 749386,
          committed: 856486,
          orgs: [],
          percentage: 87.49541732147402,
          color: "#8DB7B5",
        },
        {
          ref: "21501",
          name: "OXFAM International",
          value: 700000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "41316",
          name:
            "United Nations Framework Convention on Climate Change (UNFCCC)",
          value: 602587,
          committed: 682196,
          orgs: [],
          percentage: 88.33047980345825,
          color: "#8DB7B5",
        },
        {
          ref: "47079",
          name: "Organisation of American States",
          value: 600000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "40000",
          name: "Multilateral organisation",
          value: 598710,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "21061",
          name: "International Rehabilitation Council for Torture Victims",
          value: 500000,
          committed: 750000,
          orgs: [],
          percentage: 66.66666666666666,
          color: "#8DB7B5",
        },
        {
          ref: "30009",
          name: "Small Arms Survey",
          value: 480000,
          committed: 240000,
          orgs: [],
          percentage: 200,
          color: "#8DB7B5",
        },
        {
          ref: "63009",
          name: "Particip GmbH",
          value: 449454.08999999997,
          committed: 915764,
          orgs: [],
          percentage: 49.07968537745532,
          color: "#8DB7B5",
        },
        {
          ref: "21010",
          name: "Forum for African Women Educationalists",
          value: 405232,
          committed: 616849,
          orgs: [],
          percentage: 65.69387321694612,
          color: "#8DB7B5",
        },
        {
          ref: "21024",
          name: "International Press Service",
          value: 400000,
          committed: 600000,
          orgs: [],
          percentage: 66.66666666666666,
          color: "#8DB7B5",
        },
        {
          ref: "47081",
          name: "OECD Development Centre",
          value: 370000,
          committed: 270000,
          orgs: [],
          percentage: 137.03703703703704,
          color: "#8DB7B5",
        },
        {
          ref: "41306",
          name: "Universal Postal Union (UPU)",
          value: 366962,
          committed: 428562,
          orgs: [],
          percentage: 85.62635044637649,
          color: "#8DB7B5",
        },
        {
          ref: "21035",
          name: "World Organisation against Torture",
          value: 350000,
          committed: 800000,
          orgs: [],
          percentage: 43.75,
          color: "#8DB7B5",
        },
        {
          ref: "21039",
          name: "International Institute for Sustainable Development",
          value: 332200,
          committed: 405200,
          orgs: [],
          percentage: 81.98420533070089,
          color: "#8DB7B5",
        },
        {
          ref: "47036",
          name:
            "European and Mediterranean Plant Protection Organisation (EPPO)",
          value: 282800,
          committed: 333440,
          orgs: [],
          percentage: 84.81285988483685,
          color: "#8DB7B5",
        },
        {
          ref: "41179",
          name: "United Nations Voluntary Fund for Victims of Torture (UNVFTV)",
          value: 261000,
          committed: 261000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "21026",
          name: "International Service for Human Rights",
          value: 250000,
          committed: 1841121,
          orgs: [],
          percentage: 13.578683856194134,
          color: "#8DB7B5",
        },
        {
          ref: "41180",
          name:
            "United Nations Voluntary Fund for Technical Co-operation in the Field of Human Rights (UNVFTC)",
          value: 239000,
          committed: 239000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "13020",
          name: "Women Deliver",
          value: 200000,
          committed: 200000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41105",
          name: "UN Economic and Social Commission for Western Asia",
          value: 200000,
          committed: 200000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41136",
          name: "UN Voluntary Fund on Disability",
          value: 200000,
          committed: 200000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "11002",
          name: "Lahden kaupunki",
          value: 196322,
          committed: 259546,
          orgs: [],
          percentage: 75.64054156103349,
          color: "#8DB7B5",
        },
        {
          ref: "21038",
          name: "International Alert",
          value: 181589,
          committed: 544767,
          orgs: [],
          percentage: 33.33333333333333,
          color: "#8DB7B5",
        },
        {
          ref: "21031",
          name: "PANOS Institute",
          value: 150000,
          committed: 300000,
          orgs: [],
          percentage: 50,
          color: "#8DB7B5",
        },
        {
          ref: "16010",
          name:
            "United Nations Office of the United Nations High Commissioner for Refugees",
          value: 148870,
          committed: 2000000,
          orgs: [],
          percentage: 7.4435,
          color: "#8DB7B5",
        },
        {
          ref: "47144",
          name: "International Renewable Energy Agency (IRENA)",
          value: 133361,
          committed: 191015,
          orgs: [],
          percentage: 69.81703007617203,
          color: "#8DB7B5",
        },
        {
          ref: "47067",
          name: "Intergovernmental Panel on Climate Change (IPCC)",
          value: 102410,
          committed: 182410,
          orgs: [],
          percentage: 56.14275533139631,
          color: "#8DB7B5",
        },
        {
          ref: "47011",
          name: "Caribbean Community Secretariat",
          value: 94753,
          committed: 117500,
          orgs: [],
          percentage: 80.64085106382979,
          color: "#8DB7B5",
        },
        {
          ref: "21021",
          name:
            "International Institute for Environment and Development (IIED)",
          value: 92000,
          committed: 92000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47106",
          name: "Geneva Centre for the Democratic Control of Armed Forces",
          value: 70000,
          committed: 70000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47059",
          name: "International Development Law Organization",
          value: 50000,
          committed: 50000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41118",
          name:
            "United Nations Framework Convention on Climate Change (UNFCCC)",
          value: 25361,
          committed: 25361,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41308",
          name: "World Intellectual Property Organisation (WIPO)",
          value: 19121,
          committed: 22200,
          orgs: [],
          percentage: 86.13063063063063,
          color: "#8DB7B5",
        },
        {
          ref: "47043",
          name: "Global Crop Diversity Trust",
          value: 0,
          committed: 200000,
          orgs: [],
          percentage: 0,
          color: "#8DB7B5",
        },
      ],
      color: "#DCECEC",
    },
    {
      ref: "40",
      name: "Multilateral",
      value: 4449511637.040001,
      committed: 5402155209.1,
      percentage: 82.36549052764609,
      orgs: [
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 4449511637.040001,
          committed: 5402155209.1,
          orgs: [],
          percentage: 82.36549052764609,
          color: "#DCECEC",
        },
        {
          ref: "42001",
          name: "European Commission (EC)",
          value: 787447333.24,
          committed: 965328502.1,
          orgs: [],
          percentage: 81.57299111410957,
          color: "#8DB7B5",
        },
        {
          ref: "42003",
          name: "European Development Fund",
          value: 345983583.51,
          committed: 482791911,
          orgs: [],
          percentage: 71.66308623385366,
          color: "#8DB7B5",
        },
        {
          ref: "44002",
          name: "International Development Association",
          value: 341671076,
          committed: 482057000,
          orgs: [],
          percentage: 70.87773354603294,
          color: "#8DB7B5",
        },
        {
          ref: "41119",
          name: "UN Fund for Population",
          value: 257638767.02,
          committed: 256137880,
          orgs: [],
          percentage: 100.58596839327319,
          color: "#8DB7B5",
        },
        {
          ref: "41122",
          name: "UN Childrens Fund",
          value: 227919438,
          committed: 187574202,
          orgs: [],
          percentage: 121.50894716321383,
          color: "#8DB7B5",
        },
        {
          ref: "41114",
          name: "United Nations Development Programme (UNDP)",
          value: 197943430,
          committed: 204997639,
          orgs: [],
          percentage: 96.55888280742589,
          color: "#8DB7B5",
        },
        {
          ref: "44000",
          name: "World Bank (WB)",
          value: 197327069,
          committed: 206441305,
          orgs: [],
          percentage: 95.58507150494907,
          color: "#8DB7B5",
        },
        {
          ref: "41121",
          name:
            "United Nations Office of the United Nations High Commissioner for Refugees",
          value: 191453624,
          committed: 186967236,
          orgs: [],
          percentage: 102.39955839107553,
          color: "#8DB7B5",
        },
        {
          ref: "41140",
          name: "World Food Programme (WFP)",
          value: 190610476,
          committed: 204610476,
          orgs: [],
          percentage: 93.15773059440026,
          color: "#8DB7B5",
        },
        {
          ref: "46003",
          name: "African Development Fund (Afr.DF)",
          value: 181938706,
          committed: 258750852,
          orgs: [],
          percentage: 70.31424422130985,
          color: "#8DB7B5",
        },
        {
          ref: "41146",
          name:
            "United Nations Entity for Gender Equality and the Empowerment of Women",
          value: 137970284.96,
          committed: 137370045,
          orgs: [],
          percentage: 100.4369511271544,
          color: "#8DB7B5",
        },
        {
          ref: "44004",
          name: "International Finance Corporation",
          value: 115200000,
          committed: 116140000,
          orgs: [],
          percentage: 99.19063199586707,
          color: "#8DB7B5",
        },
        {
          ref: "47044",
          name: "Global Environment Facility",
          value: 108268304,
          committed: 149891425,
          orgs: [],
          percentage: 72.231152649326,
          color: "#8DB7B5",
        },
        {
          ref: "41108",
          name: "International Fund for Agricultural Development",
          value: 71400000,
          committed: 90620000,
          orgs: [],
          percentage: 78.79055396159788,
          color: "#8DB7B5",
        },
        {
          ref: "41147",
          name: "United Nations Central Emergency Response Fund (CERF)",
          value: 63365570,
          committed: 63365570,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41317",
          name: "Green Climate Fund",
          value: 56850000,
          committed: 180000000,
          orgs: [],
          percentage: 31.583333333333336,
          color: "#8DB7B5",
        },
        {
          ref: "44007",
          name: "IDA - Multilateral Debt Relief Iniative",
          value: 54760059.57,
          committed: 124864986,
          orgs: [],
          percentage: 43.855416417537576,
          color: "#8DB7B5",
        },
        {
          ref: "41130",
          name:
            "United Nations Relief and Works Agency for Palestine Refugees in the Near East",
          value: 50500000,
          committed: 51500000,
          orgs: [],
          percentage: 98.05825242718447,
          color: "#8DB7B5",
        },
        {
          ref: "41135",
          name: "United Nations Volunteers (UNV)",
          value: 49238725.53,
          committed: 68909417,
          orgs: [],
          percentage: 71.45427675001227,
          color: "#8DB7B5",
        },
        {
          ref: "41307",
          name: "World Health Organization (WHO)",
          value: 44855528,
          committed: 47241565,
          orgs: [],
          percentage: 94.94928459715507,
          color: "#8DB7B5",
        },
        {
          ref: "41000",
          name: "United Nations Central Emergency Response Fund (CERF)",
          value: 44312227.54,
          committed: 53805797,
          orgs: [],
          percentage: 82.35586128386872,
          color: "#8DB7B5",
        },
        {
          ref: "41116",
          name: "United Nations Environment Programme (UNEP)",
          value: 40866308,
          committed: 38313666,
          orgs: [],
          percentage: 106.66248434696904,
          color: "#8DB7B5",
        },
        {
          ref: "46004",
          name: "Asian Development Bank",
          value: 40787487.26,
          committed: 35826000,
          orgs: [],
          percentage: 113.84884514040081,
          color: "#8DB7B5",
        },
        {
          ref: "41301",
          name: "Food and Agriculture Organisation (FAO)",
          value: 38217105,
          committed: 41047624,
          orgs: [],
          percentage: 93.10430489228804,
          color: "#8DB7B5",
        },
        {
          ref: "42000",
          name: "Commission of the European Communities",
          value: 33917589,
          committed: 36317710,
          orgs: [],
          percentage: 93.39132065320199,
          color: "#8DB7B5",
        },
        {
          ref: "47131",
          name: "Organization for Security and Co-operation in Europe (OSCE)",
          value: 30905759.580000002,
          committed: 35549029,
          orgs: [],
          percentage: 86.93840717843517,
          color: "#8DB7B5",
        },
        {
          ref: "41302",
          name: "International Labour Organisation (ILO)",
          value: 27840445,
          committed: 27074674,
          orgs: [],
          percentage: 102.82836646528044,
          color: "#8DB7B5",
        },
        {
          ref: "41127",
          name:
            "United Nations Office of Co-ordination of Humanitarian Affairs",
          value: 27000000,
          committed: 28500000,
          orgs: [],
          percentage: 94.73684210526315,
          color: "#8DB7B5",
        },
        {
          ref: "41304",
          name: "UN Educational Scientific and Cultural Organization",
          value: 24275714,
          committed: 32497237,
          orgs: [],
          percentage: 74.70085533733221,
          color: "#8DB7B5",
        },
        {
          ref: "47066",
          name: "International Organization for Migration (IOM)",
          value: 23659374,
          committed: 30060408,
          orgs: [],
          percentage: 78.70609740227079,
          color: "#8DB7B5",
        },
        {
          ref: "47015",
          name:
            "Consultative Group on International Agricultural Research (CGIAR)",
          value: 22250000,
          committed: 6000000,
          orgs: [],
          percentage: 370.83333333333337,
          color: "#8DB7B5",
        },
        {
          ref: "47129",
          name: "Global Environment Facility",
          value: 22216978,
          committed: 22216978,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41128",
          name: "United Nations Office on Drugs and Crime (UNODC)",
          value: 19988000,
          committed: 21014000,
          orgs: [],
          percentage: 95.11754068716094,
          color: "#8DB7B5",
        },
        {
          ref: "41110",
          name: "Joint United Nations Programme on HIV/AIDS (UNAIDS)",
          value: 19200000,
          committed: 36000000,
          orgs: [],
          percentage: 53.333333333333336,
          color: "#8DB7B5",
        },
        {
          ref: "47000",
          name: "Intergovernmental Authority on Development",
          value: 17818312,
          committed: 13604342,
          orgs: [],
          percentage: 130.9751842463237,
          color: "#8DB7B5",
        },
        {
          ref: "41134",
          name: "United Nations University (UNU)",
          value: 17322046.849999998,
          committed: 25779359,
          orgs: [],
          percentage: 67.1934738563515,
          color: "#8DB7B5",
        },
        {
          ref: "46005",
          name: "Asian Development Fund (AsDF)",
          value: 15750000,
          committed: 28743403,
          orgs: [],
          percentage: 54.795182045772385,
          color: "#8DB7B5",
        },
        {
          ref: "46012",
          name: "Inter-American Development Bank",
          value: 15314152,
          committed: 15578957,
          orgs: [],
          percentage: 98.30023922654128,
          color: "#8DB7B5",
        },
        {
          ref: "46002",
          name: "African Development Bank (Afr.DB)",
          value: 15186541.04,
          committed: 34042000,
          orgs: [],
          percentage: 44.61118923682509,
          color: "#8DB7B5",
        },
        {
          ref: "44001",
          name: "International Bank for Reconstruction and Development (IBRD)",
          value: 14277371,
          committed: 12400234,
          orgs: [],
          percentage: 115.13791594577974,
          color: "#8DB7B5",
        },
        {
          ref: "46000",
          name: "Nordic Development Fund",
          value: 13180000,
          committed: 85980000,
          orgs: [],
          percentage: 15.329146313096068,
          color: "#8DB7B5",
        },
        {
          ref: "41310",
          name: "United Nations Department of Peacekeeping Operations (UNDPKO)",
          value: 13134008,
          committed: 16570019,
          orgs: [],
          percentage: 79.26368702413679,
          color: "#8DB7B5",
        },
        {
          ref: "45001",
          name: "WTO - International Trade Centre",
          value: 12670966.01,
          committed: 18285000,
          orgs: [],
          percentage: 69.29705228329232,
          color: "#8DB7B5",
        },
        {
          ref: "41502",
          name: "United Nations Office for Project Services (UNOPS)",
          value: 12400299.06,
          committed: 31419046,
          orgs: [],
          percentage: 39.46745887828676,
          color: "#8DB7B5",
        },
        {
          ref: "41123",
          name: "UN Industrial Development Organization",
          value: 12353837,
          committed: 11853837,
          orgs: [],
          percentage: 104.21804349089665,
          color: "#8DB7B5",
        },
        {
          ref: "46026",
          name: "Asian Infrastructure Investment Bank (AIIB)",
          value: 10087928,
          committed: 19956428,
          orgs: [],
          percentage: 50.54976772396343,
          color: "#8DB7B5",
        },
        {
          ref: "47061",
          name: "Inter-American Institute for Co-operation on Agriculture",
          value: 9855304,
          committed: 2430000,
          orgs: [],
          percentage: 405.56806584362135,
          color: "#8DB7B5",
        },
        {
          ref: "46015",
          name: "European Bank for Reconstruction and Development",
          value: 9100000,
          committed: 6000000,
          orgs: [],
          percentage: 151.66666666666666,
          color: "#8DB7B5",
        },
        {
          ref: "41141",
          name: "UN Peacebuilding Fund",
          value: 9000000,
          committed: 9000000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41305",
          name: "United Nations (UN)",
          value: 8880375,
          committed: 10511711,
          orgs: [],
          percentage: 84.48077577475256,
          color: "#8DB7B5",
        },
        {
          ref: "47078",
          name: "Montreal Protocol",
          value: 7770671,
          committed: 7770671,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41137",
          name:
            "UN Voluntary Fund for Technical Co-operation in the Field of Human Rights",
          value: 7520000,
          committed: 7520000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41126",
          name: "UN Mine Action Service",
          value: 7362366,
          committed: 7250000,
          orgs: [],
          percentage: 101.54987586206896,
          color: "#8DB7B5",
        },
        {
          ref: "41314",
          name: "United Nations Economic Commission for Europe (UNECE)",
          value: 7342783.87,
          committed: 796788,
          orgs: [],
          percentage: 921.547998965848,
          color: "#8DB7B5",
        },
        {
          ref: "47128",
          name: "Nordic Development Fund",
          value: 6942000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "41315",
          name:
            "United Nations International Strategy for Disaster Reduction (ISDR)",
          value: 6800000,
          committed: 8800000,
          orgs: [],
          percentage: 77.27272727272727,
          color: "#8DB7B5",
        },
        {
          ref: "41124",
          name: "United Nations Development Fund for Women (UNIFEM)",
          value: 6635000,
          committed: 22700000,
          orgs: [],
          percentage: 29.22907488986784,
          color: "#8DB7B5",
        },
        {
          ref: "41143",
          name: "World Health Organisation (WHO), core voluntary contributions",
          value: 6588383,
          committed: 8487441,
          orgs: [],
          percentage: 77.6250815764139,
          color: "#8DB7B5",
        },
        {
          ref: "47138",
          name: "Council of Europe",
          value: 6312469,
          committed: 7556593,
          orgs: [],
          percentage: 83.53591360550978,
          color: "#8DB7B5",
        },
        {
          ref: "41313",
          name: "United Nations High Commissioner for Human Rights",
          value: 6279675,
          committed: 7828260,
          orgs: [],
          percentage: 80.21801779705835,
          color: "#8DB7B5",
        },
        {
          ref: "47045",
          name: "Global Fund for HIV/AIDS, TB and Malaria (GFATM)",
          value: 6000000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "47130",
          name: "Global Environment Facility",
          value: 5700000,
          committed: 5700000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41309",
          name: "World Meteorological Organisation (WMO)",
          value: 5058795,
          committed: 5070795,
          orgs: [],
          percentage: 99.76335071719524,
          color: "#8DB7B5",
        },
        {
          ref: "47111",
          name: "Adaptation Fund",
          value: 5000000,
          committed: 5000000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47501",
          name: "Global Partnership for Education",
          value: 4841388,
          committed: 2000000,
          orgs: [],
          percentage: 242.0694,
          color: "#8DB7B5",
        },
        {
          ref: "41112",
          name: "United Nations Conference on Trade and Development (UNCTAD)",
          value: 4582737.52,
          committed: 5108000,
          orgs: [],
          percentage: 89.71686609240408,
          color: "#8DB7B5",
        },
        {
          ref: "47005",
          name: "African Union",
          value: 4182297,
          committed: 3082000,
          orgs: [],
          percentage: 135.7007462686567,
          color: "#8DB7B5",
        },
        {
          ref: "41312",
          name:
            "United Nations Peacebuilding Fund (Window One: Flexible Contributions Only)",
          value: 4000000,
          committed: 4000000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47058",
          name:
            "International Institute for Democracy and Electoral Assistance",
          value: 3940000,
          committed: 4790000,
          orgs: [],
          percentage: 82.25469728601253,
          color: "#8DB7B5",
        },
        {
          ref: "47080",
          name: "Organisation for Economic Co-operation and Development",
          value: 3920000,
          committed: 4130000,
          orgs: [],
          percentage: 94.91525423728814,
          color: "#8DB7B5",
        },
        {
          ref: "47077",
          name: "Mekong River Commission (MRC)",
          value: 3630000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "43000",
          name: "Kansainvalinen valuuttarahasto",
          value: 3000000,
          committed: 3000000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "44003",
          name: "IDA - HIPC Initiative Trust Fund",
          value: 3000000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "41120",
          name: "UN Human Settlements Programme",
          value: 2880000,
          committed: 1880000,
          orgs: [],
          percentage: 153.19148936170214,
          color: "#8DB7B5",
        },
        {
          ref: "45000",
          name: "World Trade Organization",
          value: 2549000,
          committed: 2549000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47122",
          name: "The Gavi Alliance",
          value: 2500000,
          committed: 2500000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47141",
          name: "African Tax Administration Forum",
          value: 2356690.48,
          committed: 4000000,
          orgs: [],
          percentage: 58.917262,
          color: "#8DB7B5",
        },
        {
          ref: "41103",
          name: "Economic Commission for Africa",
          value: 1951156,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "41107",
          name:
            "International Atom Energy Agency (Contributions to Technical Cooperation Fund Only) (IAEA-TCF)",
          value: 1947395,
          committed: 2323464,
          orgs: [],
          percentage: 83.814296240441,
          color: "#8DB7B5",
        },
        {
          ref: "47089",
          name: "Southern African Development Community (SADC)",
          value: 1534963,
          committed: 1534964,
          orgs: [],
          percentage: 99.99993485189229,
          color: "#8DB7B5",
        },
        {
          ref: "46016",
          name:
            "European Bank for Reconstruction and Development ? technical co-operation and special funds (ODA-eli",
          value: 1500000,
          committed: 1500000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41101",
          name: "Convention to Combat Desertification (UNCCD)",
          value: 1136512,
          committed: 1336512,
          orgs: [],
          percentage: 85.03567495091701,
          color: "#8DB7B5",
        },
        {
          ref: "45002",
          name: "WTO - Advisory Centre on WTO Law",
          value: 1100000,
          committed: 2200000,
          orgs: [],
          percentage: 50,
          color: "#8DB7B5",
        },
        {
          ref: "41148",
          name: "United Nations Department of Political Affairs",
          value: 1057692,
          committed: 1307692,
          orgs: [],
          percentage: 80.88234844290551,
          color: "#8DB7B5",
        },
        {
          ref: "41129",
          name:
            "United Nations Research Institute for Social Development (UNRISD)",
          value: 1004110,
          committed: 1216389,
          orgs: [],
          percentage: 82.54842817552608,
          color: "#8DB7B5",
        },
        {
          ref: "47123",
          name: "Geneva International Centre for Humanitarian Demining (GICHD)",
          value: 940000,
          committed: 1400000,
          orgs: [],
          percentage: 67.14285714285714,
          color: "#8DB7B5",
        },
        {
          ref: "41125",
          name: "United Nations Institute for Training and Research (UNITAR)",
          value: 810000,
          committed: 790000,
          orgs: [],
          percentage: 102.53164556962024,
          color: "#8DB7B5",
        },
        {
          ref: "41138",
          name: "United Nations Voluntary Fund for Victims of Torture (UNVFTV)",
          value: 760000,
          committed: 760000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41303",
          name: "International Telecommunications Union (ITU)",
          value: 749386,
          committed: 856486,
          orgs: [],
          percentage: 87.49541732147402,
          color: "#8DB7B5",
        },
        {
          ref: "41316",
          name:
            "United Nations Framework Convention on Climate Change (UNFCCC)",
          value: 602587,
          committed: 682196,
          orgs: [],
          percentage: 88.33047980345825,
          color: "#8DB7B5",
        },
        {
          ref: "47079",
          name: "Organisation of American States",
          value: 600000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "40000",
          name: "Multilateral organisation",
          value: 598710,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "47081",
          name: "OECD Development Centre",
          value: 370000,
          committed: 270000,
          orgs: [],
          percentage: 137.03703703703704,
          color: "#8DB7B5",
        },
        {
          ref: "41306",
          name: "Universal Postal Union (UPU)",
          value: 366962,
          committed: 428562,
          orgs: [],
          percentage: 85.62635044637649,
          color: "#8DB7B5",
        },
        {
          ref: "47036",
          name:
            "European and Mediterranean Plant Protection Organisation (EPPO)",
          value: 282800,
          committed: 333440,
          orgs: [],
          percentage: 84.81285988483685,
          color: "#8DB7B5",
        },
        {
          ref: "41179",
          name: "United Nations Voluntary Fund for Victims of Torture (UNVFTV)",
          value: 261000,
          committed: 261000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41180",
          name:
            "United Nations Voluntary Fund for Technical Co-operation in the Field of Human Rights (UNVFTC)",
          value: 239000,
          committed: 239000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41105",
          name: "UN Economic and Social Commission for Western Asia",
          value: 200000,
          committed: 200000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41136",
          name: "UN Voluntary Fund on Disability",
          value: 200000,
          committed: 200000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47144",
          name: "International Renewable Energy Agency (IRENA)",
          value: 133361,
          committed: 191015,
          orgs: [],
          percentage: 69.81703007617203,
          color: "#8DB7B5",
        },
        {
          ref: "47067",
          name: "Intergovernmental Panel on Climate Change (IPCC)",
          value: 102410,
          committed: 182410,
          orgs: [],
          percentage: 56.14275533139631,
          color: "#8DB7B5",
        },
        {
          ref: "47011",
          name: "Caribbean Community Secretariat",
          value: 94753,
          committed: 117500,
          orgs: [],
          percentage: 80.64085106382979,
          color: "#8DB7B5",
        },
        {
          ref: "47106",
          name: "Geneva Centre for the Democratic Control of Armed Forces",
          value: 70000,
          committed: 70000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "47059",
          name: "International Development Law Organization",
          value: 50000,
          committed: 50000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41118",
          name:
            "United Nations Framework Convention on Climate Change (UNFCCC)",
          value: 25361,
          committed: 25361,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "41308",
          name: "World Intellectual Property Organisation (WIPO)",
          value: 19121,
          committed: 22200,
          orgs: [],
          percentage: 86.13063063063063,
          color: "#8DB7B5",
        },
        {
          ref: "47043",
          name: "Global Crop Diversity Trust",
          value: 0,
          committed: 200000,
          orgs: [],
          percentage: 0,
          color: "#8DB7B5",
        },
      ],
      color: "#C1DDDD",
    },
    {
      ref: "22",
      name: "National NGO",
      value: 918276205.13,
      committed: 913049328,
      percentage: 100.57246382749652,
      orgs: [
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 918276205.13,
          committed: 913049328,
          orgs: [],
          percentage: 100.57246382749652,
          color: "#DCECEC",
        },
        {
          ref: "22000",
          name: "Finnish NGO, Abilis-saatio",
          value: 892922033.13,
          committed: 889400395,
          orgs: [],
          percentage: 100.3959564387196,
          color: "#DCECEC",
        },
        {
          ref: "22502",
          name: "Pelastakaa lapset ry",
          value: 25354172,
          committed: 23648933,
          orgs: [],
          percentage: 107.21063821357184,
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      ref: "60",
      name: "Foundation",
      value: 451525750.92999995,
      committed: 368290523,
      percentage: 122.60042622112218,
      orgs: [
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 451525750.92999995,
          committed: 368290523,
          orgs: [],
          percentage: 122.60042622112218,
          color: "#DCECEC",
        },
        {
          ref: "50000",
          name: "Other, consultant",
          value: 207593915,
          committed: 51167481,
          orgs: [],
          percentage: 405.7145494420568,
          color: "#C1DDDD",
        },
        {
          ref: "52000",
          name: "Consultants",
          value: 146833449.44,
          committed: 204304657,
          orgs: [],
          percentage: 71.86984946701435,
          color: "#C1DDDD",
        },
        {
          ref: "51000",
          name: "South Centre",
          value: 97098386.49000002,
          committed: 112818385,
          orgs: [],
          percentage: 86.06610216056542,
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      ref: "80",
      name: "Academic, Training and Research",
      value: 236744370.39000002,
      committed: 213511918,
      percentage: 110.88110331620926,
      orgs: [
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 236744370.39000002,
          committed: 213511918,
          orgs: [],
          percentage: 110.88110331620926,
          color: "#DCECEC",
        },
        {
          ref: "10000",
          name: "Public sector",
          value: 159774262,
          committed: 146087773,
          orgs: [],
          percentage: 109.36867522787139,
          color: "#DCECEC",
        },
        {
          ref: "90000",
          name: "Consultants",
          value: 75323609.39000002,
          committed: 59189552,
          orgs: [],
          percentage: 127.25828604007683,
          color: "#8DB7B5",
        },
        {
          ref: "20000",
          name: "Academy of Finland",
          value: 1497629,
          committed: 6234593,
          orgs: [],
          percentage: 24.02127933611705,
          color: "#8DB7B5",
        },
        {
          ref: "16010",
          name:
            "United Nations Office of the United Nations High Commissioner for Refugees",
          value: 148870,
          committed: 2000000,
          orgs: [],
          percentage: 7.4435,
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      ref: "21",
      name: "International NGO",
      value: 142789768.49,
      committed: 196317651,
      percentage: 72.73404493312728,
      orgs: [
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 142789768.49,
          committed: 196317651,
          orgs: [],
          percentage: 72.73404493312728,
          color: "#DCECEC",
        },
        {
          ref: "21000",
          name: "International Peace Institute",
          value: 86489821.03999999,
          committed: 101121480,
          orgs: [],
          percentage: 85.53061232885436,
          color: "#C1DDDD",
        },
        {
          ref: "21016",
          name: "International Committee of the Red Cross (ICRC)",
          value: 19000000,
          committed: 31000000,
          orgs: [],
          percentage: 61.29032258064516,
          color: "#8DB7B5",
        },
        {
          ref: "21023",
          name: "Intl. Planned Parenthood Federation",
          value: 10600000,
          committed: 23450000,
          orgs: [],
          percentage: 45.20255863539446,
          color: "#8DB7B5",
        },
        {
          ref: "21053",
          name: "IPAS",
          value: 5400000,
          committed: 14700000,
          orgs: [],
          percentage: 36.734693877551024,
          color: "#8DB7B5",
        },
        {
          ref: "21037",
          name: "Women's World Banking",
          value: 4250000,
          committed: 4450000,
          orgs: [],
          percentage: 95.50561797752809,
          color: "#8DB7B5",
        },
        {
          ref: "21033",
          name: "Transparency International",
          value: 3800000,
          committed: 4600000,
          orgs: [],
          percentage: 82.6086956521739,
          color: "#8DB7B5",
        },
        {
          ref: "21047",
          name: "IFAP/Agricord",
          value: 2500000,
          committed: 3000000,
          orgs: [],
          percentage: 83.33333333333334,
          color: "#8DB7B5",
        },
        {
          ref: "21042",
          name: "International Peacebuilding Alliance",
          value: 2250000,
          committed: 2250000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
        {
          ref: "21017",
          name:
            "International Centre for Trade and Sustainable Development (ICTSD)",
          value: 1873948.45,
          committed: 1374000,
          orgs: [],
          percentage: 136.3863500727802,
          color: "#8DB7B5",
        },
        {
          ref: "21049",
          name: "European Centre for Development Policy Management",
          value: 1286416,
          committed: 2205000,
          orgs: [],
          percentage: 58.34086167800453,
          color: "#8DB7B5",
        },
        {
          ref: "21050",
          name: "Geneva Call",
          value: 1025059,
          committed: 1267234,
          orgs: [],
          percentage: 80.88948055370989,
          color: "#8DB7B5",
        },
        {
          ref: "21057",
          name: "International Centre For Transitional Justice (ICTJ)",
          value: 953503,
          committed: 950000,
          orgs: [],
          percentage: 100.36873684210525,
          color: "#8DB7B5",
        },
        {
          ref: "21501",
          name: "OXFAM International",
          value: 700000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "21061",
          name: "International Rehabilitation Council for Torture Victims",
          value: 500000,
          committed: 750000,
          orgs: [],
          percentage: 66.66666666666666,
          color: "#8DB7B5",
        },
        {
          ref: "21010",
          name: "Forum for African Women Educationalists",
          value: 405232,
          committed: 616849,
          orgs: [],
          percentage: 65.69387321694612,
          color: "#8DB7B5",
        },
        {
          ref: "21024",
          name: "International Press Service",
          value: 400000,
          committed: 600000,
          orgs: [],
          percentage: 66.66666666666666,
          color: "#8DB7B5",
        },
        {
          ref: "21035",
          name: "World Organisation against Torture",
          value: 350000,
          committed: 800000,
          orgs: [],
          percentage: 43.75,
          color: "#8DB7B5",
        },
        {
          ref: "21039",
          name: "International Institute for Sustainable Development",
          value: 332200,
          committed: 405200,
          orgs: [],
          percentage: 81.98420533070089,
          color: "#8DB7B5",
        },
        {
          ref: "21026",
          name: "International Service for Human Rights",
          value: 250000,
          committed: 1841121,
          orgs: [],
          percentage: 13.578683856194134,
          color: "#8DB7B5",
        },
        {
          ref: "21038",
          name: "International Alert",
          value: 181589,
          committed: 544767,
          orgs: [],
          percentage: 33.33333333333333,
          color: "#8DB7B5",
        },
        {
          ref: "21031",
          name: "PANOS Institute",
          value: 150000,
          committed: 300000,
          orgs: [],
          percentage: 50,
          color: "#8DB7B5",
        },
        {
          ref: "21021",
          name:
            "International Institute for Environment and Development (IIED)",
          value: 92000,
          committed: 92000,
          orgs: [],
          percentage: 100,
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      ref: "70",
      name: "Private Sector",
      value: 81050796.80000001,
      committed: 21531030,
      percentage: 376.4371551198434,
      orgs: [
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 81050796.80000001,
          committed: 21531030,
          orgs: [],
          percentage: 376.4371551198434,
          color: "#DCECEC",
        },
        {
          ref: "61004",
          name: "FCG International Ltd",
          value: 17749550.54,
          committed: 1806089,
          orgs: [],
          percentage: 982.761676750149,
          color: "#8DB7B5",
        },
        {
          ref: "62000",
          name: "Private sector in recipient country",
          value: 17186135,
          committed: 9108773,
          orgs: [],
          percentage: 188.67672956610073,
          color: "#8DB7B5",
        },
        {
          ref: "61003",
          name: "FCA Investments Ltd",
          value: 16000000,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "61009",
          name: "Ramboll Finland oy",
          value: 13037398.37,
          committed: 4629744,
          orgs: [],
          percentage: 281.60084812464794,
          color: "#8DB7B5",
        },
        {
          ref: "61000",
          name: "Private sector in provider country",
          value: 6766745.379999999,
          committed: 991211,
          orgs: [],
          percentage: 682.674564749584,
          color: "#8DB7B5",
        },
        {
          ref: "61002",
          name: "Private export company",
          value: 5700675.21,
          committed: 1533049,
          orgs: [],
          percentage: 371.85212018663464,
          color: "#8DB7B5",
        },
        {
          ref: "62003",
          name: "PMTC Zambia Ltd",
          value: 2982240.4299999997,
          committed: 0,
          orgs: [],
          percentage: null,
          color: "#8DB7B5",
        },
        {
          ref: "63002",
          name: "Particip GmbH",
          value: 1178597.7799999998,
          committed: 2546400,
          orgs: [],
          percentage: 46.28486412189757,
          color: "#8DB7B5",
        },
        {
          ref: "63009",
          name: "Particip GmbH",
          value: 449454.08999999997,
          committed: 915764,
          orgs: [],
          percentage: 49.07968537745532,
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      ref: "23",
      name: "Regional NGO",
      value: 41723811,
      committed: 57584076,
      percentage: 72.45720327265475,
      orgs: [
        {
          ref: "23000",
          name: "Twaweza",
          value: 41723811,
          committed: 57584076,
          orgs: [],
          percentage: 72.45720327265475,
          color: "#DCECEC",
        },
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 41723811,
          committed: 57584076,
          orgs: [],
          percentage: 72.45720327265475,
          color: "#DCECEC",
        },
      ],
      color: "#8DB7B5",
    },
    {
      ref: "30",
      name: "Public Private Partnership",
      value: 19908675,
      committed: 17427003,
      percentage: 114.24038315710393,
      orgs: [
        {
          ref: "FI-3",
          name: "Ministry for Foreign Affairs of Finland",
          value: 19908675,
          committed: 17427003,
          orgs: [],
          percentage: 114.24038315710393,
          color: "#DCECEC",
        },
        {
          ref: "30011",
          name: "International Union for Conservation of Nature",
          value: 7405648,
          committed: 5731803,
          orgs: [],
          percentage: 129.20276569170295,
          color: "#C1DDDD",
        },
        {
          ref: "32000",
          name: "Multilateral Organisation Performance Assessment Network",
          value: 4136027,
          committed: 5309200,
          orgs: [],
          percentage: 77.90301740375197,
          color: "#8DB7B5",
        },
        {
          ref: "30003",
          name: "Global e-Schools and Communities Initiative",
          value: 3687000,
          committed: 3746000,
          orgs: [],
          percentage: 98.42498665242925,
          color: "#8DB7B5",
        },
        {
          ref: "31004",
          name:
            "Extractive Industries Transparency Initiative International Secretariat",
          value: 2550000,
          committed: 1250000,
          orgs: [],
          percentage: 204,
          color: "#8DB7B5",
        },
        {
          ref: "31000",
          name: "Public-Private Partnership",
          value: 1650000,
          committed: 1150000,
          orgs: [],
          percentage: 143.47826086956522,
          color: "#8DB7B5",
        },
        {
          ref: "30009",
          name: "Small Arms Survey",
          value: 480000,
          committed: 240000,
          orgs: [],
          percentage: 200,
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
  ],
};

export const CountriesTreemapMockData: TreemapDataModel = {
  name: "",
  color: "",
  children: [
    {
      name: "Regional and Unspecified",
      value: 5061377362.449997,
      committed: 5654184566.1,
      percentage: 89.5156021753479,
      ref: "Regional and Unspecified",
      orgs: [
        {
          name: "Developing countries, unspecified",
          value: 5061377362.449997,
          committed: 5654184566.1,
          percentage: 89.5156021753479,
          ref: "998",
          orgs: [],
          color: "#DCECEC",
        },
      ],
      color: "#DCECEC",
    },
    {
      name: "South of Sahara",
      value: 1282650772.4899998,
      committed: 1158839406,
      percentage: 110.6840832171356,
      ref: "South of Sahara",
      orgs: [
        {
          name: "Tanzania, United Republic of",
          value: 182367094.34,
          committed: 146465515,
          percentage: 124.51196743479174,
          ref: "TZ",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Ethiopia",
          value: 165952668.6,
          committed: 134431471,
          percentage: 123.44778150943539,
          ref: "ET",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Mozambique",
          value: 157037283.31,
          committed: 128766250,
          percentage: 121.95531306534126,
          ref: "MZ",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "South of Sahara, regional",
          value: 141035792.62,
          committed: 142503747,
          percentage: 98.96988366207663,
          ref: "289",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Somalia",
          value: 129950260.17,
          committed: 156703281,
          percentage: 82.92759369218314,
          ref: "SO",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Kenya",
          value: 119491616.97999999,
          committed: 114532044,
          percentage: 104.33029290911806,
          ref: "KE",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Zambia",
          value: 96488697.67,
          committed: 91430125,
          percentage: 105.5327198447995,
          ref: "ZM",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "South Sudan",
          value: 66367525,
          committed: 42305517,
          percentage: 156.87676148716017,
          ref: "SS",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Sudan",
          value: 34467575,
          committed: 27897948,
          percentage: 123.54878215415701,
          ref: "SD",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Congo, the Democratic Republic of the",
          value: 29856291,
          committed: 28461240,
          percentage: 104.90158194091333,
          ref: "CD",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Uganda",
          value: 26726299,
          committed: 30009434,
          percentage: 89.05965703984954,
          ref: "UG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Namibia",
          value: 19016853.8,
          committed: 10962359,
          percentage: 173.4741016965418,
          ref: "NA",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Central African Republic",
          value: 16343944,
          committed: 16268128,
          percentage: 100.46604010000412,
          ref: "CF",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Chad",
          value: 8503547,
          committed: 8503547,
          percentage: 100,
          ref: "TD",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Malawi",
          value: 7832520,
          committed: 8067641,
          percentage: 97.0856288721821,
          ref: "MW",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Ghana",
          value: 7617086.75,
          committed: 1979651,
          percentage: 384.769171434763,
          ref: "GH",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "South Africa",
          value: 6828996,
          committed: 6765879,
          percentage: 100.93287213679109,
          ref: "ZA",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Mali",
          value: 6663811,
          committed: 7130030,
          percentage: 93.46119160788945,
          ref: "ML",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Sierra Leone",
          value: 6493307,
          committed: 5119226,
          percentage: 126.84157722280673,
          ref: "SL",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Nigeria",
          value: 6450891,
          committed: 5262805,
          percentage: 122.57514766365085,
          ref: "NG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Liberia",
          value: 4846348,
          committed: 2505624,
          percentage: 193.41880505614571,
          ref: "LR",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Niger",
          value: 4750000,
          committed: 5000973,
          percentage: 94.98151659687025,
          ref: "NE",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Senegal",
          value: 4514879,
          committed: 3892712,
          percentage: 115.98286747131563,
          ref: "SN",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Cameroon",
          value: 3954292,
          committed: 3357172,
          percentage: 117.7863987904105,
          ref: "CM",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Angola",
          value: 3521987,
          committed: 1709469,
          percentage: 206.02812920269395,
          ref: "AO",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Zimbabwe",
          value: 3513401,
          committed: 3889095,
          percentage: 90.33980913297309,
          ref: "ZW",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Burundi",
          value: 3408308,
          committed: 5506958,
          percentage: 61.89093869973223,
          ref: "BI",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Eritrea",
          value: 2656352.25,
          committed: 5210564,
          percentage: 50.98012902250121,
          ref: "ER",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Côte d'Ivoire",
          value: 2188208,
          committed: 2148921,
          percentage: 101.82821983683905,
          ref: "CI",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Swaziland",
          value: 1905266,
          committed: 1368237,
          percentage: 139.24970600853507,
          ref: "SZ",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Madagascar",
          value: 1675808,
          committed: 1551328,
          percentage: 108.0240929062068,
          ref: "MG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Rwanda",
          value: 1544134,
          committed: 1089136,
          percentage: 141.77605000661075,
          ref: "RW",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Botswana",
          value: 1516654,
          committed: 1612280,
          percentage: 94.06889622149998,
          ref: "BW",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Burkina Faso",
          value: 1505151,
          committed: 2953887,
          percentage: 50.95492820138347,
          ref: "BF",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Togo",
          value: 1459359,
          committed: 882482,
          percentage: 165.3698319059199,
          ref: "TG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Mauritius",
          value: 1280680,
          committed: 100000,
          percentage: 1280.68,
          ref: "MU",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Mauritania",
          value: 1203398,
          committed: 854500,
          percentage: 140.83066120538325,
          ref: "MR",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Guinea",
          value: 510792,
          committed: 570230,
          percentage: 89.576486680813,
          ref: "GN",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Benin",
          value: 509545,
          committed: 262494,
          percentage: 194.11681790821888,
          ref: "BJ",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Lesotho",
          value: 420000,
          committed: 540000,
          percentage: 77.77777777777779,
          ref: "LS",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Guinea-Bissau",
          value: 143715,
          committed: 143715,
          percentage: 100,
          ref: "GW",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Congo",
          value: 52000,
          committed: 84065,
          percentage: 61.85689644917623,
          ref: "CG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Gabon",
          value: 41900,
          committed: 0,
          percentage: null,
          ref: "GA",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Djibouti",
          value: 19200,
          committed: 26400,
          percentage: 72.72727272727273,
          ref: "DJ",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Comoros",
          value: 13975,
          committed: 9966,
          percentage: 140.226771021473,
          ref: "KM",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Sao Tome and Principe",
          value: 3360,
          committed: 3360,
          percentage: 100,
          ref: "ST",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      name: "South & Central Asia",
      value: 563082036.32,
      committed: 533366824,
      percentage: 105.57125246320157,
      ref: "South & Central Asia",
      orgs: [
        {
          name: "Afghanistan",
          value: 209748343.82000002,
          committed: 187637004,
          percentage: 111.784104067234,
          ref: "AF",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Nepal",
          value: 165895404.17000002,
          committed: 142340533,
          percentage: 116.54825275243279,
          ref: "NP",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Myanmar",
          value: 77487382.52999999,
          committed: 104897438,
          percentage: 73.869661649887,
          ref: "MM",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "South Asia, regional",
          value: 17957561,
          committed: 14674193,
          percentage: 122.37511800478569,
          ref: "679",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "India",
          value: 14912140,
          committed: 11487562,
          percentage: 129.81118186783235,
          ref: "IN",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Kyrgyzstan",
          value: 11093166.22,
          committed: 12507345,
          percentage: 88.69321362767238,
          ref: "KG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Georgia",
          value: 10848013,
          committed: 11576558,
          percentage: 93.70672180798472,
          ref: "GE",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Bangladesh",
          value: 9995163,
          committed: 6639956,
          percentage: 150.53056074467963,
          ref: "BD",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Tajikistan",
          value: 9666297.37,
          committed: 11210782,
          percentage: 86.2232212703806,
          ref: "TJ",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Pakistan",
          value: 9207223.11,
          committed: 8425491,
          percentage: 109.27817868418587,
          ref: "PK",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Central Asia, regional",
          value: 8858242.84,
          committed: 9229918,
          percentage: 95.973147757109,
          ref: "619",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Sri Lanka",
          value: 6610321.880000001,
          committed: 2574765,
          percentage: 256.7349595011584,
          ref: "LK",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "South & Central Asia, regional",
          value: 5834050,
          committed: 5419651,
          percentage: 107.64623035689938,
          ref: "689",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Bhutan",
          value: 1778720,
          committed: 1464905,
          percentage: 121.42220826606504,
          ref: "BT",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Azerbaijan",
          value: 1039315,
          committed: 560362,
          percentage: 185.47206984056734,
          ref: "AZ",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Armenia",
          value: 1014347.38,
          committed: 913947,
          percentage: 110.98536129556746,
          ref: "AM",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Kazakhstan",
          value: 507427,
          committed: 419673,
          percentage: 120.91008952208028,
          ref: "KZ",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Maldives",
          value: 251887,
          committed: 0,
          percentage: null,
          ref: "MV",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Turkmenistan",
          value: 223109,
          committed: 244259,
          percentage: 91.34115836059264,
          ref: "TM",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Uzbekistan",
          value: 153922,
          committed: 1142482,
          percentage: 13.472597380090015,
          ref: "UZ",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      name: "Middle East",
      value: 331949132.17,
      committed: 321040617,
      percentage: 103.3978613896073,
      ref: "Middle East",
      orgs: [
        {
          name: "Middle East, regional",
          value: 112087078,
          committed: 122372106,
          percentage: 91.59528397754305,
          ref: "589",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Syrian Arab Republic",
          value: 88432319.84,
          committed: 82231368,
          percentage: 107.54085939565059,
          ref: "SY",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Yemen",
          value: 46376409,
          committed: 38067112,
          percentage: 121.8280204707938,
          ref: "YE",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Iraq",
          value: 40231513,
          committed: 40736232,
          percentage: 98.76100715451543,
          ref: "IQ",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Lebanon",
          value: 33661432.33,
          committed: 28437764,
          percentage: 118.36877305121456,
          ref: "LB",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Jordan",
          value: 11160380,
          committed: 9196035,
          percentage: 121.36078211968528,
          ref: "JO",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      name: "Far East Asia",
      value: 224715746.15,
      committed: 167443114,
      percentage: 134.20423257895214,
      ref: "Far East Asia",
      orgs: [
        {
          name: "Asia, regional",
          value: 85239188.77000001,
          committed: 59039960,
          percentage: 144.37541754770837,
          ref: "798",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Viet Nam",
          value: 83020484.9,
          committed: 59327448,
          percentage: 139.93604595970487,
          ref: "VN",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Cambodia",
          value: 12691602.89,
          committed: 12292760,
          percentage: 103.2445349132335,
          ref: "KH",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "China",
          value: 11668814.46,
          committed: 9799790,
          percentage: 119.07208685084069,
          ref: "CN",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Indonesia",
          value: 9976317,
          committed: 5331306,
          percentage: 187.12707542954766,
          ref: "ID",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Philippines",
          value: 9214256.129999999,
          committed: 9352688,
          percentage: 98.51987075801095,
          ref: "PH",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Mongolia",
          value: 4136659,
          committed: 5077466,
          percentage: 81.47093451733602,
          ref: "MN",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Korea, Democratic People's Republic of",
          value: 3326625,
          committed: 3749334,
          percentage: 88.72575769456655,
          ref: "KP",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Timor-Leste",
          value: 2617537,
          committed: 1915488,
          percentage: 136.65118236188377,
          ref: "TL",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Thailand",
          value: 2057268,
          committed: 941092,
          percentage: 218.6043447399404,
          ref: "TH",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Malaysia",
          value: 415502,
          committed: 272890,
          percentage: 152.2598849353219,
          ref: "MY",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Far East Asia, regional",
          value: 351491,
          committed: 342892,
          percentage: 102.50778670835132,
          ref: "789",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      name: "Europe",
      value: 126669752.10000002,
      committed: 138856195,
      percentage: 91.22369520495648,
      ref: "Europe",
      orgs: [
        {
          name: "Turkey",
          value: 42543714.24,
          committed: 50008666,
          percentage: 85.07268368246416,
          ref: "TR",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Ukraine",
          value: 35294696.760000005,
          committed: 49230376,
          percentage: 71.69292544099197,
          ref: "UA",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Kosovo",
          value: 15402284,
          committed: 11837919,
          percentage: 130.10972621116937,
          ref: "XK",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Serbia",
          value: 14958737,
          committed: 12223992,
          percentage: 122.37194690572441,
          ref: "RS",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Europe, regional",
          value: 8025836,
          committed: 6697333,
          percentage: 119.83629901633978,
          ref: "89",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Moldova, Republic of",
          value: 5216532.1,
          committed: 4665806,
          percentage: 111.80345046493574,
          ref: "MD",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Bosnia and Herzegovina",
          value: 1960999,
          committed: 1522491,
          percentage: 128.80200933864305,
          ref: "BA",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Albania",
          value: 1698397,
          committed: 1068441,
          percentage: 158.96029822891484,
          ref: "AL",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Belarus",
          value: 940375,
          committed: 1003296,
          percentage: 93.72857063119957,
          ref: "BY",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Macedonia, the Former Yugoslav Republic of",
          value: 627679,
          committed: 597373,
          percentage: 105.07321221414425,
          ref: "MK",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Montenegro",
          value: 502,
          committed: 502,
          percentage: 100,
          ref: "ME",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      name: "South America",
      value: 71298766,
      committed: 43672678,
      percentage: 163.2571421427374,
      ref: "South America",
      orgs: [
        {
          name: "South America, regional",
          value: 37656298,
          committed: 23611761,
          percentage: 159.48110774118035,
          ref: "489",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "America, regional",
          value: 7892204,
          committed: 1438680,
          percentage: 548.5725804209414,
          ref: "498",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Colombia",
          value: 6947273,
          committed: 6846387,
          percentage: 101.47356554632393,
          ref: "CO",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Peru",
          value: 6503827,
          committed: 3499437,
          percentage: 185.85352443835964,
          ref: "PE",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Bolivia, Plurinational State of",
          value: 4741552,
          committed: 4200577,
          percentage: 112.8785878701902,
          ref: "BO",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Brazil",
          value: 1752112,
          committed: 727906,
          percentage: 240.7058054199306,
          ref: "BR",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Ecuador",
          value: 1569916,
          committed: 532204,
          percentage: 294.98387836243245,
          ref: "EC",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Chile",
          value: 1104068,
          committed: 447829,
          percentage: 246.53785261785188,
          ref: "CL",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Paraguay",
          value: 988469,
          committed: 509108,
          percentage: 194.15703544238158,
          ref: "PY",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Uruguay",
          value: 722225,
          committed: 624498,
          percentage: 115.64888918779563,
          ref: "UY",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Venezuela, Bolivarian Republic of",
          value: 588898,
          committed: 516000,
          percentage: 114.12751937984495,
          ref: "VE",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Guyana",
          value: 464498,
          committed: 477830,
          percentage: 97.20988636125819,
          ref: "GY",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Argentina",
          value: 307475,
          committed: 239461,
          percentage: 128.4029549697028,
          ref: "AR",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Suriname",
          value: 59951,
          committed: 1000,
          percentage: 5995.1,
          ref: "SR",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      name: "North & Central America",
      value: 65251901.11,
      committed: 47866164,
      percentage: 136.32155923336578,
      ref: "North & Central America",
      orgs: [
        {
          name: "North & Central America, regional",
          value: 23454463,
          committed: 8362894,
          percentage: 280.458690496376,
          ref: "389",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Nicaragua",
          value: 16598304,
          committed: 6353918,
          percentage: 261.22943355579974,
          ref: "NI",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Honduras",
          value: 12000083.11,
          committed: 17383913,
          percentage: 69.0298157267584,
          ref: "HN",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Haiti",
          value: 7022609,
          committed: 11490529,
          percentage: 61.11649863987986,
          ref: "HT",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Guatemala",
          value: 3353700,
          committed: 1568546,
          percentage: 213.8094770570962,
          ref: "GT",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Dominican Republic",
          value: 1317530,
          committed: 1314856,
          percentage: 100.20336827759084,
          ref: "DO",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Mexico",
          value: 761924,
          committed: 890278,
          percentage: 85.58270562678175,
          ref: "MX",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "El Salvador",
          value: 367033,
          committed: 279650,
          percentage: 131.2472733774361,
          ref: "SV",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Costa Rica",
          value: 110920,
          committed: 1100,
          percentage: 10083.636363636364,
          ref: "CR",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Panama",
          value: 80874,
          committed: 68040,
          percentage: 118.86243386243387,
          ref: "PA",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Cuba",
          value: 60237,
          committed: 19500,
          percentage: 308.90769230769234,
          ref: "CU",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Antigua and Barbuda",
          value: 36662,
          committed: 45940,
          percentage: 79.80409229429691,
          ref: "AG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Saint Lucia",
          value: 31462,
          committed: 33500,
          percentage: 93.91641791044776,
          ref: "LC",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Belize",
          value: 26000,
          committed: 26000,
          percentage: 100,
          ref: "BZ",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Dominica",
          value: 19600,
          committed: 20000,
          percentage: 98,
          ref: "DM",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Jamaica",
          value: 7500,
          committed: 7500,
          percentage: 100,
          ref: "JM",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Saint Vincent and the Grenadines",
          value: 3000,
          committed: 0,
          percentage: null,
          ref: "VC",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      name: "North of Sahara",
      value: 63831278.04,
      committed: 61873375,
      percentage: 103.16437084610304,
      ref: "North of Sahara",
      orgs: [
        {
          name: "Lebanon",
          value: 33661432.33,
          committed: 28437764,
          percentage: 118.36877305121456,
          ref: "LB",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "North of Sahara, regional",
          value: 19954819,
          committed: 22076229,
          percentage: 90.39052367141144,
          ref: "189",
          orgs: [],
          color: "#C1DDDD",
        },
        {
          name: "Egypt",
          value: 6510761.32,
          committed: 7576905,
          percentage: 85.92903461241761,
          ref: "EG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Tunisia",
          value: 2376726.39,
          committed: 2679411,
          percentage: 88.70331539282328,
          ref: "TN",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Morocco",
          value: 1327539,
          committed: 1103066,
          percentage: 120.34991559888527,
          ref: "MA",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
    {
      name: "Oceania",
      value: 4279531,
      committed: 403182,
      percentage: 1061.439002733257,
      ref: "Oceania",
      orgs: [
        {
          name: "Oceania, regional",
          value: 3775408,
          committed: 0,
          percentage: null,
          ref: "889",
          orgs: [],
          color: "#DCECEC",
        },
        {
          name: "Solomon Islands",
          value: 251172,
          committed: 193172,
          percentage: 130.02505539105047,
          ref: "SB",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Papua New Guinea",
          value: 155000,
          committed: 125000,
          percentage: 124,
          ref: "PG",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Tuvalu",
          value: 60010,
          committed: 60010,
          percentage: 100,
          ref: "TV",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Palau",
          value: 21768,
          committed: 25000,
          percentage: 87.072,
          ref: "PW",
          orgs: [],
          color: "#8DB7B5",
        },
        {
          name: "Vanuatu",
          value: 16173,
          committed: 0,
          percentage: null,
          ref: "VU",
          orgs: [],
          color: "#8DB7B5",
        },
      ],
      color: "#8DB7B5",
    },
  ],
};
