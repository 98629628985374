import * as React from "react";

export const IconMap = () => (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <path
      fill="#002561"
      d="M17.5 0L17.34 0.03L12 2.1L6 0L0.36 1.9C0.15 1.97 0 2.15 0 2.38V17.5C0 17.78 0.22 18 0.5 18L0.66 17.97L6 15.9L12 18L17.64 16.1C17.85 16.03 18 15.85 18 15.62V0.5C18 0.22 17.78 0 17.5 0ZM12 16L6 13.89V2L12 4.11V16Z"
    />
  </svg>
);
